import "./App.css";
import Clock from "./Clock";

function App() {
  return (
    <div className='App'>

      <div className='App-toolbar'>
        <div className='App-toolbar-logo'><img class='App-logo' src='logo.png' /></div>
        <div className='App-toolbar-text'><p>Pracomierz</p></div>
      </div>
      

      <div className='App-center'>

      <div className='App-content'>
        <div className='App-content-text'><h3>Rozwiązanie na ręczne liczenie czasu pracy, zarobków i urlopów. </h3></div>
        <div className='App-content-image'><img class='App-logo' src='phone.png' /></div>
      </div>

      <div className='App-footer'>
        <a href='https://play.google.com/store/apps/details?id=com.paragrafxv.workhours&hl=pl&gl=PL&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='pobierz z Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png' height='40px'/></a>
        <a href='https://www.facebook.com/Pracomierz/'><img alt='Zobacz na Facebooku' src='facebook.png' height='40px'/></a>
      </div>

      <div className='App-copyright'>
        <a href="/policy.html">Polityka prywatności</a>
      </div>

      <div className='App-copyright'>
        <p>© 2024 Pracomierz. All rights reserved.</p>
      </div>

      </div>

      



    </div>
  );
}

export default App;
